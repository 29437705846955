button {
    margin: 2em;
}

.messages {
    margin: 2em;
    min-height: 20em;
}

a {
    padding-left: 0.25rem;
}

a.facebook-link {
    color: #3b5998
}
a.twitter-link {
    color: #0084B4
}
a.linkedin-link {
    color: #0077b5
}

:required:invalid {
    border: thin red solid
}

.spinner-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 20vh;
}
